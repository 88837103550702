var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "el-form",
            {
              attrs: {
                model: _vm.searchData,
                inline: true,
                size: "small",
                "label-width": "140px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "searchStr" } },
                [
                  _c("el-input", {
                    staticClass: "input-width",
                    attrs: { placeholder: "请输入产品条码或产品名称" },
                    model: {
                      value: _vm.searchData.searchStr,
                      callback: function($$v) {
                        _vm.$set(_vm.searchData, "searchStr", $$v)
                      },
                      expression: "searchData.searchStr"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "brand" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { multiple: true, placeholder: "品牌" },
                      model: {
                        value: _vm.searchData.brand,
                        callback: function($$v) {
                          _vm.$set(_vm.searchData, "brand", $$v)
                        },
                        expression: "searchData.brand"
                      }
                    },
                    _vm._l(_vm.brandDicts, function(cc) {
                      return _c("el-option", {
                        key: cc.code,
                        attrs: { label: cc.name, value: cc.code }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "saleCountry" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { multiple: true, placeholder: "国家" },
                      model: {
                        value: _vm.searchData.saleCountry,
                        callback: function($$v) {
                          _vm.$set(_vm.searchData, "saleCountry", $$v)
                        },
                        expression: "searchData.saleCountry"
                      }
                    },
                    _vm._l(_vm.countrysDicts, function(cc) {
                      return _c("el-option", {
                        key: cc.code,
                        attrs: { label: cc.name, value: cc.code }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "goodsClassifyId" } },
                [
                  _c("el-cascader", {
                    attrs: {
                      placeholder: "所属类目",
                      options: _vm.goodsClassData,
                      props: _vm.goodsClassSetProps,
                      filterable: "",
                      clearable: ""
                    },
                    model: {
                      value: _vm.searchData.goodsClassifyId,
                      callback: function($$v) {
                        _vm.$set(_vm.searchData, "goodsClassifyId", $$v)
                      },
                      expression: "searchData.goodsClassifyId"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.searchClick }
                    },
                    [_vm._v("查询")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          ref: "pagedata",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.pageData.list,
            border: "",
            "row-key": _vm.getRowKey,
            "header-cell-style": { background: "#EAEEF9", color: "#32353a" }
          },
          on: { select: _vm.onSelect, "select-all": _vm.onSelectAll }
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "55" } }),
          _c("el-table-column", {
            attrs: {
              prop: "goodsId",
              label: "产品ID",
              width: "100",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "barCode",
              label: "产品条码",
              width: "150",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "imageUrl",
              label: "产品图片",
              width: "150",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.imageUrl != ""
                      ? _c("el-image", {
                          staticStyle: { width: "100px", height: "100px" },
                          attrs: {
                            src:
                              _vm.ossHost +
                              scope.row.imageUrl +
                              "?x-oss-process=style/list_s",
                            fit: "fill"
                          }
                        })
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "title",
              label: "产品名称",
              width: "200",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "classSetName",
              label: "所属类目",
              width: "300",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", {
                      domProps: { innerHTML: _vm._s(scope.row.classSetName) }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "saleCountry",
              label: "销售国家",
              width: "100",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return _vm._l(_vm.countrysDicts, function(s) {
                    return _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              scope.row.saleProperty1.indexOf(s.code) != -1 ||
                              scope.row.saleProperty1.indexOf("/A/") != -1,
                            expression:
                              "scope.row.saleProperty1.indexOf(s.code) != -1 || scope.row.saleProperty1.indexOf('/A/') != -1"
                          }
                        ],
                        key: s.code
                      },
                      [
                        _vm._v("\n                    " + _vm._s(s.name)),
                        _c("br")
                      ]
                    )
                  })
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "brand",
              label: "品牌",
              width: "100",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return _vm._l(scope.row.brandCodes, function(s) {
                    return _c("span", { key: s.specialId }, [
                      _vm._v(" " + _vm._s(s)),
                      _c("br")
                    ])
                  })
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "enCustomParameterNameList",
              label: "搜索参数",
              width: "200"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return _vm._l(scope.row.enCustomParameterNameList, function(
                    s,
                    index
                  ) {
                    return _c(
                      "el-tag",
                      {
                        key: index,
                        staticStyle: {
                          "margin-left": "8px",
                          "margin-top": "8px"
                        },
                        attrs: { type: s }
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(s) +
                            "\n                "
                        )
                      ]
                    )
                  })
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "block", staticStyle: { display: "flex" } },
        [
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.searchData.page,
              "page-size": _vm.searchData.size,
              layout: "total,sizes, prev, pager, next, jumper",
              total: _vm.pageData.total
            },
            on: {
              "current-change": _vm.handleCurrentChange,
              "size-change": _vm.handleSizeChange
            }
          }),
          _c(
            "el-button",
            {
              staticStyle: { "margin-left": "330px", "margin-top": "10px" },
              attrs: { type: "primary", size: "small " },
              on: { click: _vm.submit }
            },
            [_vm._v("确定")]
          ),
          _c(
            "el-button",
            {
              staticStyle: { "margin-left": "20px", "margin-top": "10px" },
              attrs: { size: "small " },
              on: {
                click: function($event) {
                  return _vm.closeAddBindGoods(false)
                }
              }
            },
            [_vm._v("取消")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }