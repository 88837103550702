var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "formItem",
          attrs: { model: _vm.formItem, rules: _vm.formRules }
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "参数类型",
                prop: "type",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c("dataSelect", {
                attrs: {
                  codePlaceholder: "请选择参数类型",
                  codeType: "custom_parameter_type",
                  clearable: ""
                },
                model: {
                  value: _vm.formItem.type,
                  callback: function($$v) {
                    _vm.$set(_vm.formItem, "type", $$v)
                  },
                  expression: "formItem.type"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "启用状态",
                prop: "enable",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c("el-switch", {
                model: {
                  value: _vm.formItem.enable,
                  callback: function($$v) {
                    _vm.$set(_vm.formItem, "enable", $$v)
                  },
                  expression: "formItem.enable"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "多语言配置" } },
            [
              _c(
                "el-tabs",
                {
                  model: {
                    value: _vm.activeName,
                    callback: function($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName"
                  }
                },
                _vm._l(_vm.formItem.customParameterLangs, function(
                  item,
                  index
                ) {
                  return _c(
                    "el-tab-pane",
                    {
                      key: index,
                      attrs: { label: item.langName, name: index + "" }
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "参数名称",
                            prop: "customParameterLangs." + index + ".name",
                            "label-width": _vm.formLabelWidth,
                            rules: _vm.formRules.name
                          }
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              placeholder: "请输入参数名称",
                              maxlength: "30",
                              "show-word-limit": ""
                            },
                            model: {
                              value: item.name,
                              callback: function($$v) {
                                _vm.$set(item, "name", $$v)
                              },
                              expression: "item.name"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.onSubmit }
            },
            [_vm._v("确 定")]
          ),
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  return _vm.closeDialog(false)
                }
              }
            },
            [_vm._v("取 消")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }