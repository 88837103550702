<template>
    <div class="app-container">
        <div>
            <el-button class="filter-item" style="margin: 10px 0px 10px 20px" type="success" @click="handleAdd">
                新增
            </el-button>
        </div>

        <el-table v-loading="listLoading" :data="pageData.list" border stripe style="width: 100%; margin-top: 20px">
            <el-table-column label="参数ID" prop="customParameterId" align="center" content-overflow-tooltip> </el-table-column>
            <el-table-column label="参数名称" prop="name" align="center" content-overflow-tooltip> </el-table-column>
            <el-table-column label="参数类型" prop="type" align="center" content-overflow-tooltip> 
                <template slot-scope='scope'>  
                    <dataText class='filter-item' v-model='scope.row.type' clearable
                            :codeType="'custom_parameter_type'"
                    ></dataText>
                </template>
            </el-table-column>
            <el-table-column label="启用状态" prop="enable" align="center" content-overflow-tooltip>
                <template slot-scope="scope">
                    <el-switch v-model="scope.row.enable" active-color="#13ce66" disabled> </el-switch>
                </template>
            </el-table-column>
            <el-table-column label="关联商品数" prop="relationGoodsNum" align="center" content-overflow-tooltip> </el-table-column>

            <el-table-column label="最近操作时间" prop="updateTime" align="center" content-overflow-tooltip :formatter="formatDate">
            </el-table-column>
            <el-table-column label="最近操作人" prop="recentOperator" align="center" content-overflow-tooltip> </el-table-column>
            
            <el-table-column label="操作" align="center" width="300">
                <template slot-scope="{ row }">
                    <el-button type="primary" size="mini" @click="handleBindGoods(row)"  >关联商品</el-button>
                    <el-button type="primary" size="mini" @click="handleUpdate(row)" >编辑</el-button>
                    <el-button type="danger" size="mini" @click="handleDelete(row)"  >删除</el-button>
                </template>
            </el-table-column>
        </el-table>

        <div class="block">
            <el-pagination
                    style="margin-top: 10px"
                    background
                    @current-change="handleCurrentChange"
                    :current-page="searchData.page"
                    :page-size="searchData.limit"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pageData.total"
                    @size-change="handleSizeChange"
                    :page-sizes="pageSizes"
            ></el-pagination>
        </div>


        <el-dialog :title="isEdit ? '编辑' : '新增'" :visible.sync="dialogFormVisible" v-if="dialogFormVisible" :close-on-click-modal="false" @close="closeDialog(false,false)">     
            <add-update-dialog :isEdit="isEdit" :formItem="formItem" :customParameterType="customParameterType" @closeDialog="closeDialog" />
        </el-dialog>


        <el-dialog title="关联商品" :visible.sync="dialogBindGoodsVisible" v-if="dialogBindGoodsVisible" :close-on-click-modal="false" @close="closeBindGoodsForm(true)"  width="1200px">     
            <bindGoods ref="bindGoods" :customParameterId="selectCustomParameterId" @closeBindGoodsForm="closeBindGoodsForm" />
        </el-dialog>

    </div>
</template>

<script>
import moment from 'moment';
import dataText from '@/components/common/DataText';
import AddUpdateDialog from './components/AddUpdateDialog.vue';
import bindGoods from './components/BindGoods.vue';


function initAddBean() {
    const addBean = {
        customParameterId: null,
        enable: 0,
        type: '',
        customParameterLangs: []
    };
    return addBean;
}

 

export default {
    name: 'customParameter',
    components: {
        moment,dataText,AddUpdateDialog,bindGoods
    },
    data() {
        return {
            pageData: {},
            searchData: {
                page: 1,
                limit: 10,
            },
            defaultSearchData:{
                page: 1,
                limit: 10,
            },
            listLoading: false,
            langs: [],
            customParameterType: [],
            formItem: initAddBean(),
            isEdit: false,
            dialogFormVisible: false,
            dialogBindGoodsVisible: false,
            selectCustomParameterId: '',
            pageSizes: [10, 20, 30, 50],   
        };
    },
    created() {  
        
    },
    mounted() {    
        this.getDictItems();
        this.handleSearchList();
    },
    methods: {

        getDictItems() {
            this.$store.dispatch('getDicItems', { tag: 'lang' }).then((res) => {     
                this.langs = res;               
            });    
            this.$store.dispatch('getDicItems', { tag: 'custom_parameter_type' }).then((res) => {  
                this.customParameterType = res;              
            });
        },

        //搜索列表
        handleSearchList() {
            this.searchData.page = this.defaultSearchData.page
            this.searchData.limit = this.defaultSearchData.limit 
            this.doQueryList();
        },

        // 列表查询
        doQueryList() {
            this.loading=true
            this.$store.dispatch('getCustomParameterPage', this.searchData).then(res => {
                this.pageData = res  
            }).finally(() => {
                this.loading = false;
            });
        },  
        // 点击页数
        handleCurrentChange(val) {
            this.searchData.page = val;
            this.doQueryList();
        },
        handleSizeChange(val) {
            this.searchData.limit = val;
            this.searchData.page = 1;
            this.doQueryList();
        },

        // 新增编辑页面 关闭 
        closeDialog(status,isEdit){
            // console.log('closeDialog',status,isEdit)
            this.dialogFormVisible=false
            this.formItem= initAddBean();
            if(status){
                if(isEdit){
                    this.doQueryList();
                }else{
                    this.handleSearchList();					
                }   
            }		
        },
        
        // 打开新建表单
        handleAdd() {
            this.formItem = initAddBean();
            this.langs.forEach(element => {
                let item = {
                    lang: element.code,
                    langName: element.name,
                    name: ''
                }
                this.formItem.customParameterLangs.push(item)
            });

            this.isEdit = false;
            this.dialogFormVisible = true;
        },

        // 打开编辑表单
        handleUpdate(row) {
            this.formItem = JSON.parse(JSON.stringify(row))  
 
            let customParameterLangsMap =  new Map();
            this.formItem.customParameterLangs.forEach((item)=>{
                customParameterLangsMap.set(item.lang,item)
            })

            let customParameterLangsList = [];

            this.langs.forEach(element => {
                let item = customParameterLangsMap.get(element.code);
                if(item){
                    // 存在
                    item.langName = element.name;
                    customParameterLangsList.push(item)
                }else{
                    let item = {
                        lang: element.code,
                        langName: element.name,
                        name: ''
                    }
                    customParameterLangsList.push(item)
                }   
            });

            this.formItem.customParameterLangs = customParameterLangsList

            this.isEdit = true;
            this.dialogFormVisible = true;

        },


        // 关联商品
        handleBindGoods(row) {
            this.selectCustomParameterId = row.customParameterId;
            this.dialogBindGoodsVisible = true;
        },

       // 关闭 关联商品
       closeBindGoodsForm(status) {
            // console.log('closeBindGoodsForm',status)   
            this.dialogBindGoodsVisible = false;
            if(status){
                this.doQueryList();
            }	
        },


        //删除单条记录
        handleDelete(row) {
            this.$confirm('确认删除?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$store.dispatch('deleteCustomParameter', {id: row.customParameterId} ).then((res) => {
                    let totalPage = Math.ceil((this.pageData.total - 1) / this.searchData.limit)
                    let currentPage = this.searchData.page > totalPage ? totalPage : this.searchData.page
                    this.searchData.page = currentPage < 1 ? 1 : currentPage
                    this.$message({
                        message: '删除成功',
                        type: 'success'
                    });
                    this.doQueryList();
                    
                });
            }).catch(() => {});
        },
 

        /**
         * 时间戳转码
         */
        formatDate(row, column) {
            // 获取单元格数据
            let data = row[column.property];
            if (!data) {
                return '';
            } else {
                return moment(data).format('YYYY-MM-DD HH:mm:ss');
            }
        }
    }
};
</script>
<style scoped>
 
 
.filter-container {
    padding: auto;
    margin-left: 20px;
    padding-top: 20px;
}
.filter-item {
    margin-right: 10px;
}
</style>