<template>
    <div class="app-container">
        <div class="filter-container">
            <el-form :model="searchData" :inline="true" size="small" label-width="140px">

               <el-form-item prop="searchStr">
                  <el-input v-model="searchData.searchStr" class='input-width' placeholder='请输入产品条码或产品名称'></el-input>
               </el-form-item>
               <el-form-item prop="brand">
                    <el-select v-model="searchData.brand" :multiple="true" placeholder="品牌" >
                        <el-option v-for="cc in brandDicts" :key="cc.code" :label="cc.name" :value="cc.code"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item prop="saleCountry">
                    <el-select v-model="searchData.saleCountry" :multiple="true" placeholder="销售国家" >
                        <el-option v-for="cc in countrysDicts" :key="cc.code" :label="cc.name" :value="cc.code"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item prop="goodsClassifyId">

                    <el-cascader placeholder="所属类目" v-model="searchData.goodsClassifyId" :options="goodsClassData" :props="goodsClassSetProps" filterable clearable></el-cascader>   

                </el-form-item>

                <el-form-item>
                    <el-button type="primary" @click="searchClick">查询</el-button>
                    
                </el-form-item>

            </el-form>
        </div>

        <div>
            <el-button style="margin: 10px 0px 10px 0px" type="success"  @click="handleAddBindGoods()">
                新增
            </el-button>
            <el-button class="upload-btn" style="margin: 10px 0px 10px 20px" type="success" :loading="loading">批量导入
                
                <div id="clone_input">
                    <input class="upload-btn-file" type="file" ref="importFile" @change="handleImport" :accept=accept />
                </div>
                
            </el-button>

 
            <el-button @click="downloadTemplate" type="text" size="mini"
                style="margin-left: 5px; vertical-align: -7px; text-decoration: underline">

                导入模板下载
            </el-button>
                



            <el-button style="margin: 10px 0px 10px 10px;float: right;"  @click="batchRemove">
                批量移除({{ batchRemoveNum }})
            </el-button>

        </div>

   
        <el-table ref="pagedata"  :data="pageData.list" v-loading="loading" border   style="width: 100%;"
           
            @select="onSelect"
            @select-all="onSelectAll"
            :header-cell-style="{ background: '#EAEEF9', color: '#32353a' }"
        >
            <el-table-column type="selection" width="55" />
            <el-table-column prop="goodsId" label="产品ID" width="100" show-overflow-tooltip></el-table-column>
            <el-table-column prop="barCode" label="产品条码" width="150" show-overflow-tooltip></el-table-column>
            <el-table-column prop="imageUrl" label="产品图片" width="150" show-overflow-tooltip>
                <template slot-scope="scope">
                    <el-image
                        style="width: 100px; height: 100px"
                        v-if="scope.row.imageUrl != ''"
                        :src="ossHost + scope.row.imageUrl + '?x-oss-process=style/list_s'"
                        fit="fill"
                    ></el-image>
                </template>
            </el-table-column>
            <el-table-column prop="title" label="产品名称" width="200" show-overflow-tooltip></el-table-column>
            <el-table-column prop="classSetName" label="所属类目" width="300" show-overflow-tooltip>
                <template slot-scope="scope">
                    <div v-html="scope.row.classSetName"></div>
                </template>
            </el-table-column>
            
            <el-table-column prop="saleCountry" label="销售国家" width="100" show-overflow-tooltip>
                <template slot-scope="scope">                    
                    <span v-for="s in countrysDicts" :key="s.code"  v-show="scope.row.saleProperty1.indexOf(s.code) != -1 || scope.row.saleProperty1.indexOf('/A/') != -1">
                        {{   s.name  }}<br />
                    </span>
                </template>
            </el-table-column>

            <el-table-column prop="brand" label="品牌" width="100" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-for="s in scope.row.brandCodes" :key="s.specialId"> {{ s }}<br /> </span>
                </template>
            </el-table-column>
             

 
            <el-table-column prop="enCustomParameterNameList" label="搜索参数" width="200"  >

                <template slot-scope="scope">
                    <el-tag v-for="(s, index) in  scope.row.enCustomParameterNameList" :key="index" :type="s"  style="margin-left: 8px;margin-top: 8px;" >
                        {{ s }}
                    </el-tag>
                </template>

            </el-table-column>
            

            <el-table-column label="操作" width="100" fixed="right">
                <template slot-scope="scope">
                    <el-button @click="remove(scope.row)" type="text" size="small">移除</el-button>
 
                </template>
            </el-table-column>
        </el-table>


        <div class="block">
            <el-pagination
                style="margin-top: 10px"
                background
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                :current-page="searchData.page"
                :page-size="searchData.limit"
                :page-sizes="pageSizes"
                layout="total,sizes, prev, pager, next, jumper"
                :total="pageData.total"
            ></el-pagination>
        </div>
 
      
        <el-dialog title="选择商品" :visible.sync="addBindGoodsVisible" @close="closeAddBindGoods(false)"
                v-if="addBindGoodsVisible" :close-on-click-modal="false" width="1200px"
                :append-to-body="true"
        >
            <addBindGoods ref="addBindGoods" :customParameterId = "customParameterId" @closeAddBindGoods="closeAddBindGoods" ></addBindGoods>
        </el-dialog>


    </div>
</template>
<script>

import dataSelect from '@/components/common/DataSelect';
import addBindGoods from './AddBindGoods.vue';
 
export default {
    components: {
        dataSelect,addBindGoods
    },
    props: {
        customParameterId: {
            type: String,
            default: '0'
        }
    },
    filters: {
    },
    data() {
        return {
            //字典下拉框
            countrysDicts:[],
            brandDicts:[],
            //产品类目的数据
            goodsClassData: [],
            goodsClassSetProps: {
                label: 'title',
                value: 'goodsClassifyId',
                checkStrictly: true
            },

            loading:false,
            accept: ".xls,.xlsx",
            //查询项
            searchData: {
                searchStr: '',
                brand: [],
                saleCountry: [],         
                goodsClassifyId: null,
                fromPage: 'bindGoods',
                customParameterId: '0',
                page: 1,
                limit: 10
            }, 
            pageSizes: [10, 20, 30, 50],   
            pageData: {},
            ossHost: process.env.VUE_APP_OSS_Head,
            addBindGoodsVisible: false,
            batchRemoveNum: 0,
            selections: {} // 保存已选择过的row


        };
    },
    mounted() {
        this.searchData.customParameterId = this.customParameterId;
        this.getDictItems(); 
        this.searchClick(); 
    },
    methods: {        
        getDictItems() {
            this.$store.dispatch('getDicItems', { tag: 'countrys' }).then((res) => {     
                this.countrysDicts = res;               
            });
            this.$store.dispatch('getDicItems', { tag: 'brand' }).then((res) => {     
                this.brandDicts = res;               
            });
            this.$store.dispatch('customParameterClassifyTree', {}).then((res) => {     
                this.goodsClassData = res;               
            });    
        },


        handleCurrentChange(val) {
            this.searchData.page = val;
            this.loadlist();
        },
        handleSizeChange(val) {
            this.searchData.limit = val;
            this.searchData.page = 1;
            this.loadlist();
        },
        searchClick() {
            this.searchData.page = 1;
            this.loadlist();
        },
 
        loadlist() {
            var _this = this;
            this.$store.dispatch('getGoodsPageForCustomParameter', this.searchData).then((res) => {
                _this.pageData = res;

                if(this.pageData!= null && this.pageData.list!=null){
                    for(var item of _this.pageData.list){
                        item.saleProperty1 = "/" + item.saleProperty +"/";
                    }
                }
             
                this.checkRows();
            });
        },
        // 对已选择过的row勾选，返回到上一页时候
        checkRows() {
            const keys = Object.keys(this.selections);
            const rows = this.pageData.list.filter((row) => {
                return keys.includes(String(row.goodsId));
            });
            this.$nextTick(() => {
                rows.map((row) => {
                    this.$refs.pagedata.toggleRowSelection(row, true);
                });
            });
        },


        // 新增商品
        handleAddBindGoods() {
            this.addBindGoodsVisible = true;
        },

        closeAddBindGoods(status){
            this.addBindGoodsVisible = false;
            if(status){
                this.loadlist();
            }  
        },


        remove(row) {

            var params = {};
            params.goodsIdList = [row.goodsId];
            params.customParameterId = this.customParameterId;

            this.$confirm('确认移除?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$store.dispatch('unBindCustomParameter', params).then((res) => {
                    this.loadlist();
                    this.$message({
                        message: '移除成功',
                        type: 'success'
                    });
                });
            }).catch(() => {});

        },
        batchRemove() {
            var params = {};
            params.goodsIdList = Object.keys(this.selections);
            params.customParameterId = this.customParameterId;

            if (params.goodsIdList.length == 0) {
                this.$message({
                    message: '请选择要移除的数据',
                    type: 'error'
                });
                return;
            }

            this.$confirm('确认移除?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$store.dispatch('unBindCustomParameter', params).then((res) => {
                    this.loadlist();
                    this.$message({
                        message: '移除成功',
                        type: 'success'
                    });
                });
            }).catch(() => {});
        },
 
       
        // 导入文件
        handleImport() {
           
           var file = this.$refs.importFile.files[0];
           var ext = this.getSuffix(file.name);
           if (this.accept.indexOf(ext) < 0) {
               this.$message.error('仅支持' + this.accept + '格式的文件');
               return;
           }
           var _this = this;

           var fileParam = new FormData(); //new一个formData事件
           fileParam.append('file', this.$refs.importFile.files[0]); //将file属性添加到formData里
           fileParam.append('customParameterId', this.customParameterId);
  
           this.$confirm('是否确认导入文件, 是否继续?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
           }).then(() => {
              this.loading=true
              this.$store.dispatch('importCustomParameter', fileParam).then((res) => {
                  
                  this.$message({type: 'success',message: '导入成功'});
                  this.loadlist();
      
              }).finally(() => {
                  this.loading = false;
              });
              this.$refs.importFile.value = '';
           }).catch(()=>{
              this.$refs.importFile.value = '';
           });   
       },



        onSelect(selection, row) {
            if (this.selections[row.goodsId]) {
                delete this.selections[row.goodsId];
            } else {
                this.selections[row.goodsId] = row;
            }
            this.batchRemoveNum = Object.keys(this.selections).length;
        },
        // 全选情况
        onSelectAll(selection) {
            // 全选
            if (selection.length) {
                selection.forEach((row) => {
                    this.selections[row.goodsId] = row;
                });
            } else {
                // 取消全选
                this.pageData.list.forEach((row) => {
                    delete this.selections[row.goodsId];
                });
            }
            this.batchRemoveNum = Object.keys(this.selections).length;
        },

        /**
         * 下载模板
         */
         downloadTemplate() {
            this.$store.dispatch('downExcelTemplateCustomParameter', { fileName: '自定义参数商品导入模板.xlsx' }).then((res) => {
                this.$message({
                    type: 'success',
                    message: '下载成功'
                });
            });
        }

    }
};
</script>
<style scoped>

 

</style>
<style>
 
</style>