<template>
    <div>
        <el-form :model='formItem' :rules='formRules' ref='formItem'>

            <el-form-item label="参数类型" prop="type" :label-width="formLabelWidth">
                <dataSelect  
                    v-model='formItem.type'
                    codePlaceholder="请选择参数类型"
                    :codeType="'custom_parameter_type'"
                    clearable
                ></dataSelect>
            </el-form-item>


            <el-form-item label="启用状态" prop="enable" :label-width="formLabelWidth">
                <el-switch v-model="formItem.enable"></el-switch>
            </el-form-item>

 
  
            <el-form-item label="多语言配置"   >

                <el-tabs v-model='activeName'  >
                    <el-tab-pane v-for='(item, index) in formItem.customParameterLangs' :key='index' :label='item.langName'
                                :name= "index+''"   >
                        <el-form-item label="参数名称" :prop="'customParameterLangs.'+ index +'.name'" :label-width='formLabelWidth' :rules="formRules.name" >
                            <el-input v-model="item.name" style="width: 100%" placeholder="请输入参数名称" maxlength="30" show-word-limit></el-input>
                        </el-form-item>        
                  


                    </el-tab-pane>
                </el-tabs>

            </el-form-item>
           
        </el-form>

 
        <div  slot="footer" class='dialog-footer'>
            <el-button   type='primary'  :loading="loading" @click='onSubmit'>确 定</el-button>
            <el-button   @click='closeDialog(false)'>取 消</el-button>     
        </div>

       
    </div>
</template>
<script>

import dataSelect from '@/components/common/DataSelect';
 
export default {
    components: {
        dataSelect
    },
    props: {
        formItem: {
            type: Object,
            default: () => {
                return {};
            }
        },
        customParameterType: {
            type: Array,
            default: () => {
                return [];
            }
        },
        isEdit: {
            type: Boolean,
            default: false
        }
    },
    filters: {
    },
    data() {
        return {
            
            formRules: {
                enable: [{ required: true, message: '请选择启用状态', trigger: 'blur' }],
                type: [{ required: true, message: '请选择参数类型', trigger: 'blur' }],
                name:[
                    {
                        required: true,
                        message: '请填写参数名称',
                        trigger: 'blur'
                    },
                    {
                        min: 1,
                        max: 30,
                        message: '长度在 1 到 30 个字符',
                        trigger: 'blur'
                    }
                ],
            },
            loading: false,
            activeName: '0',
            formLabelWidth: '120px',
        };
    },
    mounted() {
         this.init();
    },
    methods: {
        init(){
 
        },
        handleChange(){

        },
        onSubmit() {
            this.$refs['formItem'].validate((valid,Object) => {
                if (valid) { 

                    this.$confirm( '是否提交数据', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning',
                    }).then(() => {
                        this.loading=true                        
                        if (this.isEdit) {                         
                            this.$store.dispatch('updateCustomParameter', this.formItem).then((res) => {
                                this.$message({
                                    message: '提交成功',
                                    type: 'success',
                                    duration: 1000
                                });
                                this.closeDialog(true,this.isEdit);
                            }).finally(() => {
                                this.loading = false;
                            });
                        } else {
                            this.$store.dispatch('addCustomParameter', this.formItem).then((res) => {
                                this.$message({
                                    message: '提交成功',
                                    type: 'success',
                                    duration: 1000
                                });
                                this.closeDialog(true,this.isEdit);
                            }).finally(() => {
                                this.loading = false;
                            });

                        }
                    }).catch(() => {
                    });

                } else {
                   
                    // 多语言配置 必填没有填写 切换到对应语言
                   for(let item  in  Object ){
                       if(item.includes('customParameterLangs.')){
                           let itemArr =  item.split('.');
                           if(itemArr.length > 1){
                               this.activeName = itemArr[1];
                               break
                           }
                       }
                   }
                   this.$message({
                       message: '验证失败，请先输入必填项',
                       type: 'error',
                       duration: 1000
                   });
                   return false;
               }

            });

 

        },
    
        closeDialog(status) {
            this.$emit('closeDialog',status,this.isEdit);
        },

 
    }
};
</script>
<style scoped>

 

</style>
<style>
 
</style>