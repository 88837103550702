var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        [
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              staticStyle: { margin: "10px 0px 10px 20px" },
              attrs: { type: "success" },
              on: { click: _vm.handleAdd }
            },
            [_vm._v("\n            新增\n        ")]
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          staticStyle: { width: "100%", "margin-top": "20px" },
          attrs: { data: _vm.pageData.list, border: "", stripe: "" }
        },
        [
          _c("el-table-column", {
            attrs: {
              label: "参数ID",
              prop: "customParameterId",
              align: "center",
              "content-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "参数名称",
              prop: "name",
              align: "center",
              "content-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "参数类型",
              prop: "type",
              align: "center",
              "content-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("dataText", {
                      staticClass: "filter-item",
                      attrs: {
                        clearable: "",
                        codeType: "custom_parameter_type"
                      },
                      model: {
                        value: scope.row.type,
                        callback: function($$v) {
                          _vm.$set(scope.row, "type", $$v)
                        },
                        expression: "scope.row.type"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "启用状态",
              prop: "enable",
              align: "center",
              "content-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-switch", {
                      attrs: { "active-color": "#13ce66", disabled: "" },
                      model: {
                        value: scope.row.enable,
                        callback: function($$v) {
                          _vm.$set(scope.row, "enable", $$v)
                        },
                        expression: "scope.row.enable"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "关联商品数",
              prop: "relationGoodsNum",
              align: "center",
              "content-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "最近操作时间",
              prop: "updateTime",
              align: "center",
              "content-overflow-tooltip": "",
              formatter: _vm.formatDate
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "最近操作人",
              prop: "recentOperator",
              align: "center",
              "content-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: { label: "操作", align: "center", width: "300" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.handleBindGoods(row)
                          }
                        }
                      },
                      [_vm._v("关联商品")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.handleUpdate(row)
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "danger", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.handleDelete(row)
                          }
                        }
                      },
                      [_vm._v("删除")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "block" },
        [
          _c("el-pagination", {
            staticStyle: { "margin-top": "10px" },
            attrs: {
              background: "",
              "current-page": _vm.searchData.page,
              "page-size": _vm.searchData.limit,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.pageData.total,
              "page-sizes": _vm.pageSizes
            },
            on: {
              "current-change": _vm.handleCurrentChange,
              "size-change": _vm.handleSizeChange
            }
          })
        ],
        1
      ),
      _vm.dialogFormVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.isEdit ? "编辑" : "新增",
                visible: _vm.dialogFormVisible,
                "close-on-click-modal": false
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogFormVisible = $event
                },
                close: function($event) {
                  return _vm.closeDialog(false, false)
                }
              }
            },
            [
              _c("add-update-dialog", {
                attrs: {
                  isEdit: _vm.isEdit,
                  formItem: _vm.formItem,
                  customParameterType: _vm.customParameterType
                },
                on: { closeDialog: _vm.closeDialog }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.dialogBindGoodsVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "关联商品",
                visible: _vm.dialogBindGoodsVisible,
                "close-on-click-modal": false,
                width: "1200px"
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogBindGoodsVisible = $event
                },
                close: function($event) {
                  return _vm.closeBindGoodsForm(true)
                }
              }
            },
            [
              _c("bindGoods", {
                ref: "bindGoods",
                attrs: { customParameterId: _vm.selectCustomParameterId },
                on: { closeBindGoodsForm: _vm.closeBindGoodsForm }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }