<template>
    <div>
        <div class="filter-container">
            <el-form :model="searchData" :inline="true" size="small" label-width="140px">

                <el-form-item prop="searchStr">
                <el-input v-model="searchData.searchStr" class='input-width' placeholder='请输入产品条码或产品名称'></el-input>
                </el-form-item>
                <el-form-item prop="brand">
                    <el-select v-model="searchData.brand" :multiple="true" placeholder="品牌" >
                        <el-option v-for="cc in brandDicts" :key="cc.code" :label="cc.name" :value="cc.code"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item prop="saleCountry">
                    <el-select v-model="searchData.saleCountry" :multiple="true" placeholder="国家" >
                        <el-option v-for="cc in countrysDicts" :key="cc.code" :label="cc.name" :value="cc.code"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item prop="goodsClassifyId">
                    <el-cascader placeholder="所属类目" v-model="searchData.goodsClassifyId" :options="goodsClassData" :props="goodsClassSetProps" filterable clearable></el-cascader>   
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" @click="searchClick">查询</el-button>   
                </el-form-item>

            </el-form>
        </div>





        <el-table ref="pagedata" :data="pageData.list" style="width: 100%" border
          
            @select="onSelect" @select-all="onSelectAll"
            :row-key="getRowKey"
            :header-cell-style="{ background: '#EAEEF9', color: '#32353a' }"
        >
            <el-table-column type="selection" width="55" />
            <el-table-column prop="goodsId" label="产品ID" width="100" show-overflow-tooltip></el-table-column>
            <el-table-column prop="barCode" label="产品条码" width="150" show-overflow-tooltip></el-table-column>
            <el-table-column prop="imageUrl" label="产品图片" width="150" show-overflow-tooltip>
                <template slot-scope="scope">
                    <el-image
                        style="width: 100px; height: 100px"
                        v-if="scope.row.imageUrl != ''"
                        :src="ossHost + scope.row.imageUrl + '?x-oss-process=style/list_s'"
                        fit="fill"
                    ></el-image>
                </template>
            </el-table-column>
            <el-table-column prop="title" label="产品名称" width="200" show-overflow-tooltip></el-table-column>
            <el-table-column prop="classSetName" label="所属类目" width="300" show-overflow-tooltip>
                <template slot-scope="scope">
                    <div v-html="scope.row.classSetName"></div>
                </template>
            </el-table-column>
           
            <el-table-column prop="saleCountry" label="销售国家" width="100" show-overflow-tooltip>
                <template slot-scope="scope">
                    
                    <span v-for="s in countrysDicts" :key="s.code"  v-show="scope.row.saleProperty1.indexOf(s.code) != -1 || scope.row.saleProperty1.indexOf('/A/') != -1">
                        {{   s.name  }}<br />
                    </span>
            
                </template>
            </el-table-column>
            <el-table-column prop="brand" label="品牌" width="100" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-for="s in scope.row.brandCodes" :key="s.specialId"> {{ s }}<br /> </span>
                </template>
            </el-table-column>
           
          

            <el-table-column prop="enCustomParameterNameList" label="搜索参数" width="200"  >

                <template slot-scope="scope">
                    <el-tag v-for="(s, index) in  scope.row.enCustomParameterNameList" :key="index" :type="s"  style="margin-left: 8px;margin-top: 8px;" >
                        {{ s }}
                    </el-tag>
                </template>

            </el-table-column>

        </el-table>



        <div class="block" style="display: flex">
            <el-pagination
                @current-change="handleCurrentChange"
                :current-page="searchData.page"
                @size-change="handleSizeChange"
                :page-size="searchData.size"
                layout="total,sizes, prev, pager, next, jumper"
                :total="pageData.total"
            ></el-pagination>
            <el-button type="primary" size="small " style="margin-left: 330px; margin-top: 10px" @click="submit">确定</el-button>
            <el-button size="small " style="margin-left: 20px; margin-top: 10px" @click="closeAddBindGoods(false)">取消</el-button>
        </div>
       
  
    </div>
</template>
<script>
 
// import cache from '@/utils/cache';
// import Config from '@/utils/config';
 
export default {
    components: {   },
    name: 'addBindGoods',
    props: {
        customParameterId: {
            type: String,
            default: '0'
        }
    },
    data() {
        return {
            //字典下拉框
            countrysDicts:[],
            brandDicts:[],
            //产品类目的数据
            goodsClassData: [],
            goodsClassSetProps: {
                label: 'title',
                value: 'goodsClassifyId',
                checkStrictly: true
            },
            //查询项
            searchData: {
                searchStr: '',
                brand: [],
                saleCountry: [],         
                goodsClassifyId: null,
                fromPage: 'addBindGoods',
                customParameterId: '0',
                page: 1,
                limit: 10
            },
            selections: {}, // 保存已选择过的row
            pageSizes: [10, 20, 30, 50],
            pageData: {},
            ossHost: process.env.VUE_APP_OSS_Head,           
        };
    },

    created() {
        this.getDictItems();   
        this.searchData.customParameterId = this.customParameterId;
    },
    mounted() {
        this.searchClick(); 
    },
    methods: {
        getDictItems() {
            this.$store.dispatch('getDicItems', { tag: 'countrys' }).then((res) => {     
                this.countrysDicts = res;               
            });
            this.$store.dispatch('getDicItems', { tag: 'brand' }).then((res) => {     
                this.brandDicts = res;               
            });
            this.$store.dispatch('customParameterClassifyTree', {}).then((res) => {     
                this.goodsClassData = res;               
            });  
        },

        searchClick() {
            this.searchData.page = 1;
            this.loadlist();
        },
   
        handleCurrentChange(val) {
            this.searchData.page = val;
            this.loadlist();
        },
        handleSizeChange(val) {
            this.searchData.limit = val;
            this.searchData.page = 1;
            this.loadlist();
        },
        loadlist() {
            var _this = this;
            this.$store.dispatch('getGoodsPageForCustomParameter', this.searchData).then((res) => {
                _this.pageData = res;
                if(this.pageData!= null && this.pageData.list!=null){
                    for(var item of _this.pageData.list){
                        item.saleProperty1 = "/" + item.saleProperty +"/";
                    }
                }
                this.checkRows();
            });
        },

        // 对已选择过的row勾选，返回到上一页时候
        checkRows() {
            const keys = Object.keys(this.selections);
            const rows = this.pageData.list.filter((row) => {
                return keys.includes(String(row.goodsId));
            });
            this.$nextTick(() => {
                rows.map((row) => {
                    this.$refs.pagedata.toggleRowSelection(row, true);
                });
            });
        },

        getRowKey(row) {
            return row.goodsId;
        },

        onSelect(selection, row) {
            if (this.selections[row.goodsId]) {
                delete this.selections[row.goodsId];
            } else {
                this.selections[row.goodsId] = row;
            }
        },

        // 全选情况
        onSelectAll(selection) {
            // 全选
            if (selection.length) {
                selection.forEach((row) => {
                    this.selections[row.goodsId] = row;
                });
            } else {
                // 取消全选
                this.pageData.list.forEach((row) => {
                    delete this.selections[row.goodsId];
                });
            }
        },
       
 
        //提交
        submit() {
            var params = {};
            params.goodsIdList = Object.keys(this.selections);
            params.customParameterId = this.customParameterId;
            console.log(params);
            this.$store.dispatch('bindCustomParameter', params).then((data) => {
                this.$message({
                    message: '保存成功',
                    type: 'success'
                });
                this.closeAddBindGoods(true);
            });
        },
          
        closeAddBindGoods(status) {
            this.$emit('closeAddBindGoods',status);
        },
       
 
    }
};
</script>
